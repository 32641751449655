import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

import { Popover, Text, Button } from '@mantine/core';
import ReactGA from "react-ga4";


const Footer = () => {
    const currentVersion = useSelector(state => state.mainapp.versionSlice.versions.currentVersion)
    const [opened, setOpened] = useState(false)


    return (
        <>
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-promo">
                        <h1>
                            Download Frigid Client
                        </h1>
                        <p>Explore the Frigid Network, Build Apps, Stay Secure and Anonymous.</p>

                        <Popover opened={opened} onChange={setOpened} width={332} position="bottom-center" shadow="md" offset={20}>
                            <Popover.Target>
                                <button onClick={
                                    () => {
                                        setOpened(!opened)
                                    }

                                } style={{ width: 'fit-content' }} className="hero-btn">
                                    <img src="/assets/applelogo.png" />      Download Mac App
                                </button>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <div className='browser-options'>

                                    <a href={'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-' + currentVersion + '-arm64.dmg'}
                                        onClick={
                                            () => {
                                                ReactGA.event({
                                                    category: "Download",
                                                    action: "Downloaded Frigid Client",
                                                    label: "Landing Page - Apple Silicon Mac",
                                                });
                                            }
                                        }

                                        download>
                                        <div className="menu-text">
                                            <img src={'/assets/applelogo.png'} />
                                            <p>Apple Silicon Mac  <br />
                                                <span>
                                                    For M1+ Macs
                                                </span></p>

                                        </div>
                                        <i className="material-icons">
                                            get_app
                                        </i>
                                    </a>


                                    <a href={'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-' + currentVersion + '.dmg'} onClick={
                                        () => {
                                            ReactGA.event({
                                                category: "Download",
                                                action: "Downloaded Frigid Client",
                                                label: "Landing Page - Intel Mac",
                                            });
                                        }

                                    } download >

                                        <div className="menu-text">
                                            <img src={'/assets/intel.png'} />
                                            <p>Intel Mac
                                                <br />
                                                <span>
                                                    More Common Macs
                                                </span>
                                            </p>
                                        </div>

                                        <i className="material-icons">
                                            get_app

                                        </i>
                                    </a>


                                </div>
                            </Popover.Dropdown>
                        </Popover>

                    </div>
                    <div className="footer-links">

                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/developer">Developer</Link></li>
                            <li><Link to="/community">Community</Link></li>

                            {/*       
                            <li><Link to="/about">About</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="footer-footer">

                </div>
            </footer>

        </>
    )
}

export default Footer