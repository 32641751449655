import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './publicPages/landingPage';
import NonLodable from './publicPages/nonLodable';
import loadable from '@loadable/component';
import Page404 from './publicPages/404';
import { useSelector } from 'react-redux';
import './main.css'
import Navbar from './publicPages/components/navbar';
import ReactGA from "react-ga4";
import { useEffect } from 'react';



const LoadableDeveloperPage = loadable(() => import('./publicPages/developer'))
const LoadableDownloadsPage = loadable(() => import('./publicPages/downloads'))
const LoadableCommunityPage = loadable(() => import('./publicPages/community'))


const App = () => {

    useEffect(() => {
        ReactGA.initialize('G-GPG3PBPYEN')
    }, [])

    return (
        <>
            <Navbar />
            <Routes>
                <Route path='/' >
                    <Route path='/' element={<LandingPage />} />
                    {/* <Route path='/lodable' element={<LoadableLandingPage />} /> */}
                    {/* <Route path='/nonLodable' element={<NonLodable />} /> */}
                    <Route path='/developer' element={<LoadableDeveloperPage />} />
                    {/*     <Route path='/changelog' element={<LoadableDownloadsPage />} /> */}
                    <Route path='/community' element={<LoadableCommunityPage />} />


                    <Route path="*" element={<Page404 />} />
                </Route>


            </Routes>
        </>


    )
};

export default App;
