import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import Render from './render';
import { createReduxStore } from './store/store';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component'
import { MantineProvider, Text } from '@mantine/core';
import '@mantine/core/styles.css';
const NextRender = require('./index.js').default

const store = createReduxStore(window.serverReduxStore)

if (process.env.NODE_COMPILE == 'design') {

    const root = createRoot(document.getElementById('root'));
    root.render(
        <MantineProvider>
            <Provider store={store}>
                <Render />
            </Provider>
        </MantineProvider>
    );
    if (module.hot) {
        module.hot.accept('./index.js', () => {
            root.render(
                <MantineProvider>
                    <Provider store={store}>
                        <NextRender />
                    </Provider>
                </MantineProvider>,
                root
            );
        });
    }
}
else if (process.env.NODE_COMPILE == 'split') {

    const root = createRoot(document.getElementById('root'));
    root.render(
        <MantineProvider>
            <Provider store={store}>
                <Render />
            </Provider>
        </MantineProvider>
    );
    if (module.hot) {
        module.hot.accept('./index.js', () => {
            root.render(
                <MantineProvider>
                    <Provider store={store}>
                        <NextRender />
                    </Provider>
                </MantineProvider>,
                root
            );
        });
    }
}
else {
    if (process.env.NODE_COMPILE == 'development') {
        const root = createRoot(document.getElementById('root'));
        root.render(
            <MantineProvider>
                <Provider store={store}>
                    <Render />
                </Provider>
            </MantineProvider>
        );
        if (module.hot) {
            module.hot.accept(() => {
                // Re-hydrate or re-render your app logic here
                hydrateRoot(
                    document.getElementById('root'),
                    <MantineProvider>
                        <Provider store={store}>
                            <Render />
                        </Provider>
                    </MantineProvider>
                );
            });
        }
    }
    else {
        loadableReady(() => {
            hydrateRoot(
                document.getElementById('root'),
                <MantineProvider>
                    <Provider store={store}>
                        <Render />
                    </Provider>
                </MantineProvider>
            )
        })
    }
}
