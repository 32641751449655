import { createSlice, current } from "@reduxjs/toolkit";

import versions from "../../../public/versions.json"




const initialState = () => ({
    versions: versions,
})

const versionSlice = createSlice({
    name: 'Version Manager',
    initialState: initialState(),
    reducers: {
        resetChain: state => initialState(),

    }
})

export const {
    resetChain,

} = versionSlice.actions



export default versionSlice.reducer