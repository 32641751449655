import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import chainSlice from './schemas/chainSlice';
import versionSlice from './schemas/versionSlice';

export const createReduxStore = (preloadedState) => {
  return configureStore({
    reducer: {
      auth: authSlice,
      mainapp: combineReducers({
        chainSlice: chainSlice,
        versionSlice: versionSlice
      })
    },
    preloadedState
  });
};

// For client-side: 
