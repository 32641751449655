import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useNavigate } from "../../node_modules/react-router-dom/dist/index";
import { useEffect } from "react";
import { LoopServerCall, MetaTagHandler, ServerCallHandler, serverHtmlAttributes } from "../../library/client/serverCallHandler";
import NonLodable from "./nonLodable";
import Footer from "./components/footer";
import ReactGA from "react-ga4";
import { useRef } from "react";
import { Popover, Text, Button } from '@mantine/core';
import { useState } from "react";
const useIntersectionObserver = (callback, options) => {
    const targetRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (callback) {
                callback(entry);
            }
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [targetRef, callback, options]);

    return targetRef;
};

const VideoBlock = ({ src, title, description, align = "left" }) => {
    const videoRef = useIntersectionObserver(
        (entry) => {
            if (entry.isIntersecting) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        },
        { threshold: 0.7 }
    );

    return (
        <div className={`video-block ${align === "right" ? "video-block-right" : "video-block-left"}`}>
            {align === "left" ? (
                <>
                    <video ref={videoRef} loop muted>
                        <source src={src} type="video/mp4" />
                    </video>
                    <div className="video-block-text">
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                </>
            ) : (
                <>
                    <div className="video-block-text">
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                    <video ref={videoRef} loop muted>
                        <source src={src} type="video/mp4" />
                    </video>
                </>
            )}
        </div>
    );
};


const LandingPage = () => {
    const chainList = useSelector(state => state.mainapp.chainSlice.chainList)
    const currentVersion = useSelector(state => state.mainapp.versionSlice.versions.currentVersion)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [opened, setOpened] = useState(false)

    return (
        <>
            <MetaTagHandler>
                {() => (
                    <>
                        <title>
                            Frigid - Explore & Build Apps Hosted on the Blockchain
                        </title>
                        <meta
                            name="description"
                            content="Explore and build dApps with confidence without revealing your identity. By Leveraging blockchain technology, Frigid hosts all applications without centralized servers, offering unparalleled security and anonymity."
                        />
                        <meta
                            name="keywords"
                            content="Frigid, Blockchain, dApps, Decentralized, Hosting, Domain, Name, System, DNS, ERC20"
                        />
                    </>
                )}
            </MetaTagHandler>

            <div className="hero-wrapper">
                <div className="hero">
                    <h1>
                        Explore & Build Apps <br /> Hosted on the Blockchain
                    </h1>
                    <p>
                        Download Frigid Network Client and explore apps and <br /> websites hosted entirely on the blockchain.{" "}
                    </p>

                    <Popover opened={opened} onChange={setOpened} width={332} position="bottom-center" shadow="md" offset={20}>
                        <Popover.Target>
                            <button onClick={
                                () => {
                                    setOpened(!opened)
                                }

                            } style={{ width: 'fit-content' }} className="hero-btn">
                                <img src="/assets/applelogo.png" />      Download Mac App
                            </button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <div className='browser-options'>

                                <a href={'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-' + currentVersion + '-arm64.dmg'}
                                    onClick={
                                        () => {
                                            ReactGA.event({
                                                category: "Download",
                                                action: "Downloaded Frigid Client",
                                                label: "Landing Page - Apple Silicon Mac",
                                            });
                                        }
                                    }

                                    download>
                                    <div className="menu-text">
                                        <img src={'/assets/applelogo.png'} />
                                        <p>Apple Silicon Mac  <br />
                                            <span>
                                                For M1+ Macs
                                            </span></p>

                                    </div>
                                    <i className="material-icons">
                                        get_app
                                    </i>
                                </a>


                                <a href={'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-' + currentVersion + '.dmg'} onClick={
                                    () => {
                                        ReactGA.event({
                                            category: "Download",
                                            action: "Downloaded Frigid Client",
                                            label: "Landing Page - Intel Mac",
                                        });
                                    }

                                } download >

                                    <div className="menu-text">
                                        <img src={'/assets/intel.png'} />
                                        <p>Intel Mac
                                            <br />
                                            <span>
                                                More Common Macs
                                            </span>
                                        </p>
                                    </div>

                                    <i className="material-icons">
                                        get_app

                                    </i>
                                </a>


                            </div>
                        </Popover.Dropdown>
                    </Popover>
                    {/* 
                    <a onClick={
                        () => {
                            ReactGA.event({
                                category: "Download",
                                action: "Downloaded Frigid Client",
                                label: "Landing Page",
                            });
                        }

                    } href={'/assets/build/Frigid ' + currentVersion + '.exe'} download={true} className="hero-btn">
                        <img src="/assets/applelogo.png" />      Download Mac App
                    </a> */}
                    <span>Mac OS Big Sur 11.6.0+ Recommended</span>
                </div>
            </div>

            <div className="video-block-grouper">
                <VideoBlock
                    src="/assets/video/Frigid-App-Clip.mp4"
                    title="Browse Anonymously"
                    description="With Frigids proxy technology you can use any web browser to access apps built on the Frigid Network. So support for major browsers like Chrome, Edge, Brave, and more."
                    align="left"
                />
                <VideoBlock
                    src="/assets/video/Frigid-Developer-Clip.mp4"
                    title="Host Freely"
                    description="Frigid stores things like domain names, website data, and more on the blockchain. This means that your data is secure and can be accessed from anywhere in the world."
                    align="right"
                />



            </div >

            <div className="feature-wrapper">
                <h1>Stay Anonymous and Secure</h1>
                <p>
                    Explore and build dApps with confidence without revealing your identity. By Leveraging blockchain technology, Frigid hosts all applications without centralized servers, offering unparalleled security and anonymity.
                </p>
            </div>



            <div className="feature-block-grouper">
                <div className="feature-blocks">
                    <div className="feature-block">
                        <img src="/assets/globe.svg" alt="globe" />
                        <h2>Censorship Free Apps</h2>
                        <p>
                            Whether your hosting a news publication free of censorship or building the next DApp platform Frigid allows for maximum flexibility and anonymity when deploying
                        </p>
                    </div>

                    <div className="feature-block">
                        <img src="/assets/blockchain.svg" alt="globe" />
                        <h2>All Data Stored on The Blockchain</h2>
                        <p>
                            Frigid stores things like domain names, website data, and more on the blockchain. This means that your data is secure and can be accessed from anywhere in the world.
                        </p>
                    </div>

                    {/*    <div className="feature-block">
                        <img src="/assets/browser.svg" alt="globe" />
                        <h2>Full Browser Support</h2>
                        <p>
                            With Frigids' proxy technology you can use any web browser to access apps built on the Frigid Network. So support for major browsers like Chrome, Edge, Brave, and more.
                        </p>
                    </div> */}
                </div>

                {/*   <div className="feature-blocks">
                   <div className="feature-block">
                        <img src="/assets/blockchain.svg" alt="globe" />
                        <h2>All Data Stored on The Blockchain</h2>
                        <p>
                            Frigid stores things like domain names, website data, and more on the blockchain. This means that your data is secure and can be accessed from anywhere in the world.
                        </p>
                    </div>

                    <div className="feature-block">
                        <img src="/assets/dns.svg" alt="globe" />
                        <h2>All New Domain Name System</h2>
                        <p>
                            Frigid uses a new domain system that is decentralized and secure. Domain name endings like .poly, .ethr, and more are available for purchase and use on the Frigid Network.
                        </p>
                    </div>
                </div> */}
            </div>

            <div className="feature-wrapper">
                <h1>Massive Network Support</h1>
                <p>
                    Frigid supports many (ERC20) networks to maximize your experience no matter what blockchain technology you prefer. Up to 7 networks are supported for domain purchases, website hosting and browsing. Build and explore today!
                </p>
            </div>

            <div className="feature-chips">
                {chainList.map((chain, index) => {
                    return (
                        <div className="feature-chip" key={index}>
                            <div className="chip-content">
                                <img src={"/assets/chains/" + chain.image} alt={chain.name} />
                                <span>{chain.name}</span>
                            </div>
                        </div>
                    );
                })}
            </div>

            <Footer />
        </>
    );
}

export default LandingPage